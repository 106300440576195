import { keyframes } from '@emotion/react';
import { Theme } from 'theme-ui';

const makeTheme = <T extends Theme>(t: T) => t;

const breakpoints = [576, 768, 992, 1200];

const animationResetStroke = keyframes`
to { stroke-dashoffset: 0; }
`;

const heading = {
  fontFamily: 'body',
  fontWeight: 'heading',
  lineHeight: 'heading',
  letterSpacing: -0.38,
};

const bodyText = {
  fontFamily: 'body',
  fontWeight: 'body',
  lineHeight: 'body',
  letterSpacing: -0.18,
};

export const black = '#373E4D';
const blackBright = '#303644';
export const greyAlto = '#D8D8D8';
const greySilver = '#A8A8A8';
const blueCasper = '#A9BFCF';
export const blue = '#2E5CA9';
export const blueAlt = '#2C5CAA';
const blueTiers = '#2F3CCF';
export const blueLight = '#F1F5F8';
export const blueDeep = '#003399';
const blueMidnight = `#002774`;
const lavenderBlush = '#FFE6EE';
const coral = '#F38374';
export const coralAlt = '#FF8571';
const coralLight = '#F49D8B';
const seashell = '#FFF7F4';
const white = '#fff';
const gold = '#B3A168';
const goldDark = '#C2863D';
const redCinnabar = '#E43C2F';
export const purpleDeep = '#4C1FCA';

const theme = makeTheme({
  useBodyStyles: false, // to prevent default styles being applied globally by theme-ui
  useCustomProperties: false,
  shadows: {
    small: `0 2px 4px rgba(0,0,0, 0.12)`,
    large: `0 12px 24px rgba(21,45,64,0.1)`,
  },
  colors: {
    black,
    blackBright,
    greyAlto,
    greySilver,
    blueCasper,
    blue,
    blueAlt,
    blueLight,
    blueTiers,
    blueDeep,
    blueMidnight,
    lavenderBlush,
    coral,
    coralAlt,
    coralLight,
    seashell,
    white,
    gold,
    goldDark,
    background: white,
    text: black,
    redCinnabar,
    purpleDeep,
  },
  palette: {
    black: {
      0: '#FFFFFF',
      100: '#E7E8EA',
      200: '#DADCDF',
      300: '#BFC1C7',
      400: '#A1A4AB',
      500: '#8C9098',
      600: '#777C86',
      700: '#626773',
      800: '#4D5360',
      main: '#373E4D',
    },
    blue: {
      100: '#F2F6FC',
      200: '#DCE6F8',
      300: '#BED3F6',
      400: '#A0BBE8',
      500: '#F4B6AE',
      600: '#F4AAA0',
      700: '#F39C91',
      800: '#F49083',
      main: '#2C5CAA',
    },
    blueLight: {
      100: '#EFF2F3',
      200: '#E8EDF0',
      300: '#E1E9EC',
      400: '#D9E4E9',
      500: '#D3E1E6',
      600: '#CDDDE4',
      700: '#C6D9E1',
      800: '#BFD5DE',
      main: '#B8D1DB',
    },
    bluePremium: {
      100: '#FBEEED',
      200: '#F5DDDA',
      300: '#F4D0CB',
      400: '#F3C2BC',
      500: '#F4B6AE',
      600: '#F4AAA0',
      700: '#F39C91',
      800: '#F49083',
      main: '#003399',
    },
    coral: {
      100: '#FBEEED',
      200: '#F5DDDA',
      300: '#F4D0CB',
      400: '#F3C2BC',
      500: '#F4B6AE',
      600: '#F4AAA0',
      700: '#F39C91',
      800: '#F49083',
      main: '#F38374',
    },
    gold: {
      100: '#F7F6F0',
      200: '#EFEBDF',
      300: '#E6E0CE',
      400: '#DED6BD',
      500: '#D5CBAC',
      600: '#CDC19B',
      700: '#C4B68A',
      800: '#BCAC79',
      main: '#B3A168',
    },
    green: {
      100: '#E6EFF2',
      200: '#C9DDE4',
      300: '#ACCBD5',
      400: '#8FB9C7',
      500: '#73A8B9',
      600: '#5696AA',
      700: '#39849C',
      800: '#1C718D',
      main: '#00607F',
    },
    pearlescent: {
      blueLight: '#EEF5F9',
      lavender: '#EFEBF3',
      main: '#D5E9F6',
    },
    pink: {
      100: '#FEF8F9',
      200: '#FDEFF2',
      300: '#FCE7EB',
      400: '#FBDFE5',
      500: '#FBDFE5',
      600: '#FAD6DE',
      700: '#F8CED7',
      800: '#F7C6D0',
      main: '#F5B5C3',
    },
    red: {
      100: '#FCECEA',
      200: '#F9D6D3',
      300: '#F6C0BB',
      400: '#F3AAA4',
      500: '#F0948D',
      600: '#ED7E75',
      700: '#EA685E',
      800: '#E75246',
      main: '#E43C2F',
    },
    yellow: {
      100: '#FEFDED',
      200: '#FDFBD8',
      300: '#FCF8C3',
      400: '#FBF6AE',
      500: '#FAF39A',
      600: '#F8F185',
      700: '#F7EF70',
      800: '#F7EF70',
      main: '#F5EA47',
    },
  },
  fontSizes: [18, 24, 32, 48, 60],
  sizes: {
    navDesktop: 72,
    navMobile: 50,
    mobileContainerGutter: 30,
  },
  heights: {
    nav: 72,
    navMobile: 50,
    hero: {
      mobile: 628,
      desktop: 725,
    },
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  space_v2: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 80, 96, 112, 128],
  zIndices: {
    nav: 10,
  },
  z: {
    nav: 10,
  },
  radii: {
    small: 4,
    mid: 8,
  },
  centerFlex: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  columnFlex: () => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  transformCenter: () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  }),
  breakpoints: breakpoints.map((point) => `${point}px`),
  // custom media query
  cmq: (pixels) => `@media (min-width: ${pixels}px)`,
  mq: breakpoints.map((bp) => `@media (min-width: ${bp}px)`),
  halfBordersCurved: (alt = false) =>
    alt
      ? {
          borderRadius: [`0 32px`, , `0 56px`, `0 64px`],
          img: {
            borderRadius: [`0 32px`, , `0 56px`, `0 64px`],
          },
        }
      : {
          borderRadius: [`32px 0`, , `56px 0`, `64px 0`],
          img: {
            borderRadius: [`32px 0`, , `56px 0`, `64px 0`],
          },
        },
  revealAnim: (condition) => ({
    transform: `translateY(${condition ? 0 : 32}px)`,
    opacity: condition ? 1 : 0,
    transition: `0.8s ease-out all`,
    transitionProperty: 'opacity, transform',
  }),
  fonts: {
    body: 'Basis Grotesque Pro, Barlow',
    numeric: 'Barlow',
  },
  fontWeights: {
    body: 400,
    heading: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.13,
  },
  buttons: {
    primary: {
      bg: 'blue',
      color: 'white',
      '&:hover, &:focus': {
        color: 'white',
      },
    },
    inverted: {
      bg: 'white',
      color: 'blue',
      '&:hover, &:focus': {
        color: 'blue',
      },
    },
    secondary: {
      bg: 'coral',
      color: 'white',
      '&:hover, &:focus': {
        color: 'white',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: [24, , 40],
    },
    h2: {
      ...heading,
      fontSize: [24, , 40],
    },
    h3: {
      ...heading,
      fontSize: [16, , 24],
    },
    p: {
      ...bodyText,
      fontSize: [18],
    },
    link: {
      color: blueAlt,
      cursor: 'pointer',
      display: 'inline-block',
      fontWeight: 700,
      textDecoration: 'none !important',
      '&:hover': { color: blueDeep },
    },
  },
  animations: {
    keyframes: {
      fadeIn: keyframes`
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      `,
    },
    inView: {
      fadeIn: (delay = 0) => ({
        opacity: 0,
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 1,
        },
      }),
      fadeOut: (delay = 0) => ({
        opacity: 1,
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 0,
        },
      }),
      fadeInUp: (delay = 0) => ({
        opacity: 0,
        transform: 'translateY(75px)',
        transition: 'all 0.75s',
        transitionDelay: `${delay}s`,
        '.inView &': {
          opacity: 1,
          transform: `translateY(0px)`,
        },
      }),
      drawSVG: (duration = 4) => ({
        strokeDasharray: 1000,
        strokeDashoffset: 1000,
        animation: 'none 0 ease 0 1 normal none running',
        '.inView &': {
          animation: `${animationResetStroke} ${duration}s linear forwards`,
        },
      }),
    },
  },
});

export default theme;

export type TThemeUI = typeof theme;
